import { Box, Button, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import PatientService from '../services/PatientService';
import IPatient from '../types/Patient';

interface AddPatientFormProps {
    onPatientAdded: (patient: IPatient) => void;
}

const AddPatientForm: React.FC<AddPatientFormProps> = ({ onPatientAdded }) => {
    let disabled = false;

    const form = useForm({
        initialValues: { name: '' },
        validate: {
            name: (value: string) => value.trim().length < 2 || value.trim().length > 30 ? "Invalid name" : null,
        },
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        disabled = true;
        e.preventDefault();
        try {
            const response = await PatientService.post(form.values as IPatient);
            form.reset();
            onPatientAdded(response as IPatient);
        }
        catch (error: any) {
            if (error.response && error.response.data && Array.isArray(error.response.data.errors)) {
                const errors = error.response.data.errors;
                errors.forEach(({ field, defaultMessage }: { field: string; defaultMessage: string }) => {
                    form.setFieldError(field, defaultMessage);
                });
            } else {
                console.error('An error occurred:', error);
            }
        }
        finally {
            disabled = false;
        }
    }

    return (
        <Box maw={340} mx="auto">
            <form onSubmit={handleSubmit}>
                <TextInput
                    withAsterisk
                    label="Name"
                    placeholder="your@email.com"
                    {...form.getInputProps('name')}
                />

                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={disabled}>Submit</Button>
                </Group>
            </form>
        </Box>
    )
}

export default AddPatientForm;