import { Avatar, Badge, Table } from '@mantine/core';
import React from 'react';
import IPatient from '../../../types/Patient';

interface PatientsListProps {
    patients: IPatient[]
}


const PatientsList: React.FC<PatientsListProps> = ({ patients }) => {
    const rows = patients.map((patient) => (
        <Table.Tr key={patient.id}>
            <Table.Td><Badge variant="outline" color="blue">{patient.status}</Badge></Table.Td>
            <Table.Td>25/05/2024 12:00</Table.Td>
            <Table.Td>{patient.name}</Table.Td>
            <Table.Td>EU</Table.Td>
            <Table.Td>Clinical</Table.Td>
            <Table.Td>Ventricles</Table.Td>
            <Table.Td>Catheter ablation</Table.Td>
            <Table.Td>
                <Badge color="grey" me="xs">Alpha</Badge>
                <Badge color="grey" me="xs">Beta</Badge>
            </Table.Td>

            <Table.Td>
                <Badge me="xs">CT</Badge>
                <Badge me="xs">MR</Badge>
                <Badge me="xs">Unknown</Badge>
            </Table.Td>
            <Table.Td>
                <Avatar src={null} alt="no image here" color="indigo" >CA</Avatar>
            </Table.Td>
            <Table.Td>
                <Avatar src={null} alt="no image here" color="blue" >RW</Avatar>
            </Table.Td>
            <Table.Td>
                25/05/2024 12:00
            </Table.Td>
        </Table.Tr >
    ));

    return (
        <Table striped highlightOnHover>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Status</Table.Th>
                    <Table.Th>Requested</Table.Th>
                    <Table.Th>Patient</Table.Th>
                    <Table.Th>Region</Table.Th>
                    <Table.Th>Service</Table.Th>
                    <Table.Th>Area of interest</Table.Th>
                    <Table.Th>Study</Table.Th>
                    <Table.Th>Etiology</Table.Th>
                    <Table.Th>Modalities</Table.Th>
                    <Table.Th>CA in charge</Table.Th>
                    <Table.Th>Reviewer</Table.Th>
                    <Table.Th>Delivery date</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
        </Table>
    )
}

export default PatientsList;
