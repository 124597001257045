import http from '../configs/http-common';
import IPatient from '../types/Patient';

const URL = '/patients'

const get = async () => {
    const response = await http.get<IPatient[]>(URL);
    return response.data;
};

const post = async (data: IPatient): Promise<IPatient> => {
    const response = await http.post<IPatient>(URL, data);
    return response.data;
};


http.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error: Error) {
        return Promise.reject(error);
    }
);

const PatientService = {
    get,
    post,
};

export default PatientService;