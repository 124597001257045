import { Container } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import AddPatientForm from '../../components/AddPatientForm';
import { HeaderMenu } from '../../components/HeaderMenu/HeaderMenu';
import PatientService from '../../services/PatientService';
import IPatient from '../../types/Patient';
import PatientsList from './PatientsList/PatientsList';

const Patients = () => {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const handlePatientAdded = (newPatient: IPatient) => {
    setPatients([...patients, newPatient]);
  };

  useEffect(() => {
    PatientService.get().then((data) => {
      setPatients(data);
    });
  }, []);

  return (
    <div>
      <HeaderMenu></HeaderMenu>
      <Container size="xxl">
        <PatientsList patients={patients}></PatientsList>
        <AddPatientForm onPatientAdded={handlePatientAdded}></AddPatientForm>
      </Container>
    </div>

  );
};

export default Patients;
