import { Avatar, Burger, Button, Card, Center, Container, Group, Image, Menu, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconUser } from '@tabler/icons-react';
import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import classes from './HeaderMenu.module.css';
import logo from './logo.png';

const links = [
    { link: '/', label: 'Dashboard' },
    {
        link: '#2',
        label: 'Help',
        links: [
            { link: '/faq', label: 'FAQ' },
            { link: '/demo', label: 'Book a demo' },
            { link: '/forums', label: 'Forums' },
        ],
    },
];

export function HeaderMenu() {
    const [opened, { toggle }] = useDisclosure(false);
    const auth = useAuth();

    const items = links.map((link) => {
        const menuItems = link.links?.map((item) => (
            <Menu.Item key={item.link}>{item.label}</Menu.Item>
        ));

        if (menuItems) {
            return (
                <Menu key={link.label} trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
                    <Menu.Target>
                        <a
                            href={link.link}
                            className={classes.link}
                            onClick={(event) => event.preventDefault()}
                        >
                            <Center>
                                <span className={classes.linkLabel}>{link.label}</span>
                                <IconChevronDown size="0.9em" stroke={1.5} />
                            </Center>
                        </a>
                    </Menu.Target>
                    <Menu.Dropdown>{menuItems}</Menu.Dropdown>
                </Menu>
            );
        }

        return (
            <a
                key={link.label}
                href={link.link}
                className={classes.link}
                onClick={(event) => event.preventDefault()}
            >
                {link.label}
            </a>
        );
    });

    return (
        <header className={classes.header}>
            <Container size='default' >
                <div className={classes.inner}>
                    <div className={classes.inner}>
                        <Image className={classes.logo}
                            radius="md"
                            src={logo}
                        />
                        <Group gap={5}>
                            {items}
                        </Group>
                    </div>
                    <Group gap={1}>
                        <Menu>
                            <Menu.Target>
                                <Button leftSection={<IconUser style={{ width: '15px', height: '15px' }} />}>{auth.user?.first_name} {auth.user?.last_name} - inHEART</Button>
                            </Menu.Target>


                            <Menu.Dropdown>

                                <Card withBorder padding="xl" radius="md" className={classes.card}>
                                    <Card.Section
                                        h={160}
                                        style={{
                                            backgroundImage: 'url(http://localhost:3000/admin.jpg',
                                            backgroundPosition: 50
                                        }}
                                    />
                                    <Avatar
                                        src="null"
                                        size={80}
                                        radius={80}
                                        mx="auto"
                                        mt={-30}
                                        className={classes.avatar}
                                        color="blue."
                                    >{auth.user?.first_name.substring(0, 1)}{auth.user?.last_name.substring(0, 1)}</Avatar>
                                    <Text ta="center" fz="lg" fw={500} mt="sm">
                                        {auth.user?.first_name} {auth.user?.last_name}
                                    </Text>
                                    <Text ta="center" fz="sm">
                                        ROLE - TODO
                                    </Text>
                                    <Button fullWidth radius="md" mt="xl" size="md" color="primary" onClick={() => auth.logout()} component='a'>
                                        Log out
                                    </Button>
                                </Card>
                            </Menu.Dropdown>
                        </Menu>

                    </Group>
                    <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" />
                </div>
            </Container>
        </header >
    );
}
